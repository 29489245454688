.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 1.1em; /* Adjust this value to match the height of your text */
  margin: 0 -0.3em -0.2em -0.55em; /* Add margin to the logo for spacing */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0368B1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header .Stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Forecase-sub, .Forecast-title {
  margin: -5px; 
}

.Forecase-sub {
  font-weight: 200;
  font-size: 20px; 
}

.App-header .Forecast-title {
  font-size: 45px;
}

.Weather-info {
  font-size: 16px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.Weather-info p,h3{
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
